import service from '@/utils/request';

// @Tags SysDictionaryDetail
// @Summary 创建SysDictionaryDetail
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysDictionaryDetail true "创建SysDictionaryDetail"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysDictionaryDetail/createSysDictionaryDetail [post]
export var createSysDictionaryDetail = function createSysDictionaryDetail(data) {
  return service({
    url: "/sysDictionaryDetail/createSysDictionaryDetail",
    method: 'post',
    data: data
  });
};

// @Tags SysDictionaryDetail
// @Summary 删除SysDictionaryDetail
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysDictionaryDetail true "删除SysDictionaryDetail"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /sysDictionaryDetail/deleteSysDictionaryDetail [delete]
export var deleteSysDictionaryDetail = function deleteSysDictionaryDetail(data) {
  return service({
    url: "/sysDictionaryDetail/deleteSysDictionaryDetail",
    method: 'delete',
    data: data
  });
};

// @Tags SysDictionaryDetail
// @Summary 更新SysDictionaryDetail
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysDictionaryDetail true "更新SysDictionaryDetail"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /sysDictionaryDetail/updateSysDictionaryDetail [put]
export var updateSysDictionaryDetail = function updateSysDictionaryDetail(data) {
  return service({
    url: "/sysDictionaryDetail/updateSysDictionaryDetail",
    method: 'put',
    data: data
  });
};

// @Tags SysDictionaryDetail
// @Summary 用id查询SysDictionaryDetail
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysDictionaryDetail true "用id查询SysDictionaryDetail"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /sysDictionaryDetail/findSysDictionaryDetail [get]
export var findSysDictionaryDetail = function findSysDictionaryDetail(params) {
  return service({
    url: "/sysDictionaryDetail/findSysDictionaryDetail",
    method: 'get',
    params: params
  });
};

// @Tags SysDictionaryDetail
// @Summary 分页获取SysDictionaryDetail列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取SysDictionaryDetail列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysDictionaryDetail/getSysDictionaryDetailList [get]
export var getSysDictionaryDetailList = function getSysDictionaryDetailList(params) {
  return service({
    url: "/sysDictionaryDetail/getSysDictionaryDetailList",
    method: 'get',
    params: params
  });
};